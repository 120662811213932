import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {ContentPage} from "../contentPage";
import {Post} from "../../../common/post";
import {formatStringDateLocalToHu} from "../../../common/contentAux";
import * as styles from './hirekPage.module.scss';
import * as postStyles from "../../../common/post.module.scss";

const makeExternalLinks = (newsLinks) => newsLinks.map((link, i) => 
<a key={i} href={link.link} className={postStyles.galleryLink} target="_blank" >{link.text}</a>);

export default function HirekPage() {
	const data = useStaticQuery(graphql`
		query HirekQuery {
			allMarkdownRemark(
				filter: {
					fileAbsolutePath: {regex: "/(news)/"}, 
					frontmatter: {date_created: {ne: null}}
				}, 
				sort: {fields: [frontmatter___date_created], order: DESC}) {
                edges {
                    node {
                        frontmatter {
                            title
                            newsCategory
                            date_created
                            files {
                                title
                                file
                            }
							newsLinks {
								text
								link
							}
                        }
                        html
                    }
                }
            }
		}	
	`);

	const news = data.allMarkdownRemark.edges;

	return (
		<ContentPage activePage={0}>
			<div className={styles.container}>		
				{news.map(({node}, i) => {
					const {frontmatter, html} = node;
					const {title, newsCategory, date_created, files, newsLinks} = frontmatter;
					return (<Post 
						key={i}
						header1={title}
						header2={newsCategory}
						header3={formatStringDateLocalToHu(date_created, 'LLL')}
						files={files}
						links={newsLinks && makeExternalLinks(newsLinks)}
						html={html}
						index={i}
					/>);
				})}
			</div>
		</ContentPage>
	);
}