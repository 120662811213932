import React from "react";
import {moveImgsToDivInHtml} from "./contentAux";
import {DownloadButton} from "./downloadButton";
import img1 from "../content/img/landing/img1r.jpg";
import img2 from "../content/img/landing/img2r.jpg";
import img3 from "../content/img/landing/img3r.jpg";
import img4 from "../content/img/landing/img4r.jpg";
import * as styles from './post.module.scss';

const eventHeaderBg = (i) => {
    let img;
    switch (i % 4) {
      case 0: 
        img =  img1;
        break;
      case 1: 
        img = img2;
        break;
      case 2: 
        img = img3;
        break;
      case 3: 
        img = img4;
        break;
      default: 
        img = null;
    }

    return `linear-gradient(30deg, rgba(60, 56, 86, 1) 5%, rgba(60, 56, 86, .7) 50%, rgba(154,102,144, .5) 100%), url(${img})`;
};

export const Post = ({header1, header2, header3, files, html, index, links}) => (
    <div className={styles.container}>
        <div className={styles.headerBox} style={{backgroundImage: eventHeaderBg(index)}}>
            <p className={styles.header1}>{header1}</p>
            {header2 && <p className={styles.header2}>{header2}</p>}
            {header3 && <p className={styles.header3}>{header3}</p>}
        </div>
        {(html || (links && links.length > 0)) && <div className={styles.descContainer} >
            {moveImgsToDivInHtml(html)}
            {links}
        </div>}
        {(files && files.length > 0) && <div className={styles.filesContainer}>
                <p className={styles.filesTitle}>Csatolmányok:</p>
                {files.map((f, i) => <DownloadButton key={i} text={f.title} url={f.file.length && f.file[0]}/>)}
        </div>}
    </div> 
);
